<template>
  <v-menu
    offset-x
    v-model="menu"
    :nudge-bottom="50"
    :close-on-content-click="false"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on" icon>
        <v-icon size="24px">fas fa-user</v-icon>
      </v-btn>
    </template>

    <v-card width="310">
      <v-list>
        <v-list-item>
          <v-list-item-avatar color="primary">
            <img
              v-if="me && me.profile_image"
              :src="me.profile_image"
              :alt="me.short_name"
            />

            <span
              v-else-if="me && me.initials_name"
              class="white--text lighten-1 headline"
            >
              {{ me.name }}
            </span>

            <v-icon color="white" v-else> fas fa-user-tie </v-icon>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>{{ me ? me.name : "" }}</v-list-item-title>
            <v-list-item-subtitle></v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-action> </v-list-item-action>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <!-- <v-list>
        <v-list-item>
          <v-list-item-title></v-list-item-title>
        </v-list-item>
      </v-list> -->

      <v-card-actions>
        <v-btn
          outlined
          color="primary"
          class="no-text-transform"
          @click.stop="handleClickButtonShowModal()"
        >
          Alterar Senha
        </v-btn>

        <v-spacer></v-spacer>

        <v-btn
          color="primary"
          class="no-text-transform"
          @click.stop="handleClickButtonLogout()"
        >
          Sair
          <v-icon right>fas fa-sign-out-alt</v-icon></v-btn
        >
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
import { mapGetters } from "vuex";
import ToastService from "@/services/ToastService";
import AuthService from "@/services/Auth/AuthService";

export default {
  name: "UserMenu",

  data: () => ({
    menu: false,
    AuthService: new AuthService("/auth"),
  }),

  computed: {
    ...mapGetters({
      me: "getMe",
    }),
  },

  methods: {
    async handleClickButtonLogout() {
      try {
        await this.AuthService.logout("logout");

        await this.$router.push({ path: "/entrar" });
      } catch (exception) {
        ToastService(exception, "error");
      }
    },

    handleClickButtonShowModal() {
      this.$store.dispatch("modalChangePassword", true);
    },
  },
};
</script>